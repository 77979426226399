@use '../../../styles/base/settings' as *;

//mobile
.container {
  width: 100%;
  height: 100vh;
  background-color: $primaryBackground;
  overflow-x: hidden;
  overflow-y: scroll;
  //
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0px;
}
.container::-webkit-scrollbar {
  display: none;
}
.contentContainer {
  width: 100%;
}
.headerContainer {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 5;
  // background-color: blue;
}
.bodyContainer {
  width: 100%;
  // background-color: yellow;
}
.footerContainer {
  width: 100%;
  // background-color: green;
}
